import React, { useEffect, useRef, useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { createQueueProcess, createQueueProcessPoli, getAllDoctorAndPoli, getQueueMachine } from "../../actions";
import Button from "../../components/Button";
import { encode as base64_encode } from "base-64";
import "./index.css";
import PatientLablePreview from "./Preview";
import { useReactToPrint } from 'react-to-print';
import Modal from "../../components/Modal";
import DropDownPoli from "../../components/DropDownPoli";
import { HiArrowSmLeft } from "react-icons/hi";
import Topbar from "../../components/Topbar";
import PatientDisplayPoliclinic from "./RawatJalan/poli";
import PatientDisplayDoctor from "./RawatJalan/doctor";
import { isPayer, isDoubleQueue } from "../../helpers/kioskConfigs";
import BackButton from "../../components/BackButton";

const PatientDisplay = () => {
  const { machineId } = useParams();
  let componentRef = useRef(null);

  const hospital = localStorage.getItem("hospitalName") || 'HOSPITA';

  const [openPage, setOpenPage] = useState({
    poli: false,
    doctor: false,
    payer: false
  })

  const initialState = {
    poli: false,
    doctor: false,
    payer: false
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => {
      setTimeout(() => {
        setLoading(false)
      }, 2000);
    },
  });

  const mappedTitle = [
    {title: 'Pendaftaran Rawat Jalan', subTitle: 'Pengambilan nomor antrian administrasi pendaftaran rawat jalan' , doOnClick : isPayer == true ? ()=> setOpenPage({...initialState, payer : true }) : () => handleCreateAndPrint()},
    {title: 'Pendaftaran Poliklinik', subTitle: 'Pengambilan nomor antrian poliklinik dokter spesialis' , doOnClick : ()=> setOpenPage({...initialState, poli: true})}
  ]

  const queueProcess = useSelector((state) => state.queueProcess);
  const queueMachine = useSelector((state) => state.queueMachine);
  const { doctorList } = useSelector((state)=> state.doctorList)
  const [dataEncoded, setDataEncoded] = useState();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [poli, setPoli] = useState()
  const [ doctor, setDoctor ]= useState()
  const [payerId, setPayerId] = useState('')
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getQueueMachine(machineId))
    if(isDoubleQueue){
      dispatch(getAllDoctorAndPoli())
    }

  }, [dispatch, machineId]);

  useEffect(() => {
    if (queueProcess.createQueueProcess) {
      const encodedData = base64_encode(
        JSON.stringify(queueProcess.createQueueProcess)
      );
      setDataEncoded(encodedData);
      // window.open(`/patient-display/preview/${encodedData}`, "_blank");

    }
  }, [queueProcess.createQueueProcess]);

  const handleCreate = async (itemId) => {
    let body = {
      queueMachine: machineId,
      queueItem: itemId,
      poliId: poli?.value,
      isPayer
    };
    if (!queueProcess.createLoading) {
    return new Promise((resolve, reject) => {
      dispatch(createQueueProcess(body)).then((res) => {
      setTimeout(() => {
        resolve()
      }, 1000);
    })
    .catch((err) => {
      // open modal fail to get kiosk queue number
      setModal(true)
    }
    );
    });
    }
  };

  const handleCreatePoli = async (body) => {
    if (!queueProcess.createLoading) {
    return new Promise((resolve, reject) => {
      dispatch(createQueueProcessPoli(body)).then((res) => {
      setTimeout(() => {
        resolve()
      }, 1000);
    })
    .catch((err) => {
      // open modal fail to get kiosk queue number
      setModal(true)
    }
    );
    });
    }
  };
  
  function handleCreateAndPrint(itemId, poli){
    let doctorHospitalId;
    if(poli && !loading){
      setLoading(true)
      // Start: Filter doctor to get doctorHospitalId
      if(!poli.doctorHospitalId && poli?.doctorId) {
        const findDoctor = doctorList?.doctor?.length > 0 && doctorList.doctor.find((data) => data.doctorId == poli.doctorId);
        doctorHospitalId = findDoctor?.doctorHospitalId;
      }
      // End
      handleCreatePoli({ 
        queueMachine : machineId,
        valueUuid : poli.doctorId,
        prefix: `${poli.prefixPoli}${poli.prefixDoctor}`,
        nameDoctor: poli.namaDokter,
        doctorHospitalId : poli?.doctorHospitalId || doctorHospitalId,
      }).then(() => {
        handlePrint()
      }).catch((error) => {
        console.error("Erorr dispatching: ", error);
        setLoading(false)
      })
    } else if (!loading){
      setLoading(true)
      handleCreate(itemId).then( () => {
        handlePrint()
      }).catch((error) => {
        console.error("Erorr dispatching: ", error);
        setLoading(false)
      })
    }
  }

  const renderModal = () => {
    return (
      <Modal
        size="md"
        show={modal}
        handleClose={() => setModal(false)}
        title=""
      >
        <Row className="m-0 text-center">
          <Col>
            <Image src="/img/icon/icon-timedate.svg" height={"100px"} />
          </Col>
        </Row>

        <Row className="m-0 mb-5 text-center">
          <Col>
            <span>Gagal mengambil nomor antrian kiosk</span>
          </Col>
        </Row>

        <Row className="m-0">
          <Col>
            <Button title="Tutup" onClick={() => setModal(false)} />
          </Col>
        </Row>
      </Modal>
    );
  };

  const listAntrianRender = ()=>{
    return (
    <>
     <Row className="justify-content-center title-hospital-name">
                <div>Silakan pilih loket pendaftaran yang ingin Anda tuju</div>
     </Row>
      <div className="patient-container"  style={{justifyContent:'center'}}>
        { 
       queueMachine.queueMachine?.queueItems?.length &&
          queueMachine.queueMachine.queueItems.map((data, index) => (
            <Row className="m-0 mb-4 d-flex justify-content-center" key={index}>
              <Col md={12} className="text-center">
                <Button
                  className={(queueMachine.queueMachine?.queueItems?.length < 4 ? "button-control-h-default" : (queueMachine.queueMachine?.queueItems?.length < 5 ? "button-control-h-100" : "button-control-h-80"))}
                  title={data.name}
                  onClick={() => handleCreateAndPrint(data._id)}
                />
              </Col>
            </Row>
          ))
        }
      </div>
      <div>
      <div style={{ display: "none" }}>
        <PatientLablePreview ref={componentRef} data={dataEncoded} />
      </div>
      </div>
      <BackButton styleButton={{fsArrow:40, fwWord: 'normal', fsWord: 25 }} handleBack={() => setOpenPage({...initialState })}/>

</>
    )
  }

  return (
    <>
      <div className="patient-bg font-family-patient-display" >
                <div className="patient-header">
                    <Topbar />
                </div>
      {
       isDoubleQueue? !openPage.doctor && !openPage.poli && !openPage.payer ? 
        <div className="patient-container">
                    <Row className="m-0 mb-4 d-flex justify-content-center" >
                        <Col md={11}>
                            <Row className="justify-content-center title-hospital-name">
                                <div><span>Selamat Datang di</span> <span>{hospital}</span></div>
                            </Row>
                            <Row className="justify-content-center title-hospital-service">
                                Silakan pilih layanan yang Anda butuhkan
                            </Row>
                        </Col>
                    </Row>
                   {mappedTitle.map((dataMap)=>{
                    return (
                      <Row className="m-0 mb-4 d-flex justify-content-center" >
                      <Col md={11} className="text-center">
                          <Button
                              className={("custom-dropdown")}
                              titleReact={true}
                              title={()=>{
                                return(
                                  <Row style={{width:'100%', height:'100%', padding:10}} className="m-0 mb-4 d-flex justify-content-center">
                                  <div className="button-patient-style">
                                    <div>{dataMap.title}</div>
                                    <div style={{fontSize:'30px', fontWeight:'normal'}}>{dataMap.subTitle}</div>
                                  </div>
                                  </Row>
                                )
                              }}
                              onClick={() => dataMap.doOnClick()}
                          />
                      </Col>
                  </Row>
                    )
                   })}
                    <div style={{ display: "none" }}>
                    <PatientLablePreview ref={componentRef} data={dataEncoded} />
                    </div>
                </div> 
                : openPage.poli ?
                <PatientDisplayPoliclinic
                  handleSelectClinic={(input)=>{
                  setDoctor(doctorList.doctor.filter((data)=> data.poliId === input))
                  setPoli(doctorList.poli.filter((data)=> data.poliId === input))
                  setOpenPage({...initialState, doctor: true })
                  }} 
                  poli={doctorList?.poli || []} 
                  handleBack={()=> setOpenPage({...initialState })}
                />
                : openPage.doctor ?
                <>
                <PatientDisplayDoctor 
                  handleSelectDoctor={(input)=> handleCreateAndPrint('', input)}
                  doctor={doctor || []}
                  handleBack={()=> setOpenPage({...initialState, poli: true })}
                  poliName={poli?.[0]?.namaPoli || []}
                /> 
                <div style={{ display: "none" }}>
                    <PatientLablePreview ref={componentRef} data={dataEncoded} />
                  </div>
                </>
                : 
                listAntrianRender() : 
                <div className="patient-container"  style={{justifyContent:'center'}}>
                { queueMachine.queueMachine?.queueItems?.length &&
                  queueMachine.queueMachine.queueItems.map((data, index) => (
                    <Row className="m-0 mb-4 d-flex justify-content-center" key={index}>
                      <Col md={9} className="text-center">
                        <Button
                          className={(queueMachine.queueMachine?.queueItems?.length < 4 ? "button-control-h-default" : (queueMachine.queueMachine?.queueItems?.length < 5 ? "button-control-h-100" : "button-control-h-80"))}
                          title={data.name}
                          onClick={() =>handleCreateAndPrint(data._id)}
                        />
                      </Col>
                    </Row>
                  ))
                }
                 <div style={{ display: "none" }}>
                    <PatientLablePreview ref={componentRef} data={dataEncoded} />
                    </div>
              </div>
      }

      </div>
    {renderModal()}
    </>
  );
};

export default PatientDisplay;
